import React from 'react'
import PropTypes from 'prop-types'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import { Avatar, Card, Flexbox } from '@/components'
import { Link } from 'react-router-dom'
import currency from '@/lib/masks/currency'
import { LICENSING_TYPE } from '@/lib/enums'
import dateMask from '@/lib/masks/date'

function UserCard({
  user, lowIncome, title, renderFooter,
}) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">{title}</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-user" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns>
          <Flexbox.Column className="col-auto">
            <Avatar
              avatarSize="lg"
              name={user?.fullName}
              photoURL={user?.photoURL}
              alt="Imagem de usuário"
            />
          </Flexbox.Column>
          <Flexbox.Column>
            <Link to={`/app/users/${user?.id}`}>
              <div className="text-primary text-bold">{user?.fullName}</div>
            </Link>
            <div className="text-small">{user?.documentNumber ? documentNumberMask(user?.documentNumber) : '-'}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>
            {
              Boolean(user?.partnerBlacklist) && (
                <>
                  <div className="toast toast-error mt-2">
                    <span className="h6">
                      Este usuário foi <b>bloqueado</b> por
                      &nbsp;{user?.partnerBlacklist?.author?.fullName} em
                      &nbsp;{dateMask(user?.partnerBlacklist?.createdAt, { hour: '2-digit', minute: '2-digit' })}
                    </span>
                    <div className="pre">{user?.partnerBlacklist?.description}</div>
                    <div className="pre mt-2 text-small">{user?.partnerBlacklist?.info}</div>
                  </div>
                  <div className="divider" />
                </>
              )
            }
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>E-mail</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {user?.email}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Fone</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {phoneNumber(user?.phoneNumber) || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Data de nascimento</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {user?.birthdayDate ? dateMask(user?.birthdayDate) : '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        {
          Boolean(user?.licensings?.length) && (
            <div className="pt-2">
              {
                user.licensings.map((licensing) => (
                  <span className="chip" key={licensing.id}>
                    {LICENSING_TYPE.t(licensing.type)}
                  </span>
                ))
              }
            </div>
          )
        }
        {
          Boolean(lowIncome) && (
            <>
              <div className="divider text-center" data-content="Baixa renda" />
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Renda familiar</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {currency(lowIncome?.familyIncome, 'R$ ')}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Membros na fam.</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.numberOfFamilyMembers}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nº CRAS</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.cras || '-'}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nº NIS</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.nis || '-'}
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
        }
      </Card.Body>
      {
        renderFooter()
      }
    </Card.Base>
  )
}

UserCard.propTypes = {
  user: PropTypes.objectOf(Object).isRequired,
  lowIncome: PropTypes.objectOf(Object),
  title: PropTypes.string,
  renderFooter: PropTypes.func,
}

UserCard.defaultProps = {
  lowIncome: null,
  title: 'Usuário',
  renderFooter: () => null,
}

export default UserCard
