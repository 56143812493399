import {
  Card, Flexbox, LoadManager, PageHeader, Styles,
} from '@/components'
import { CASTRATION_STATUS } from '@/lib/enums'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import castrations from '@/services/api/castrations'
import React from 'react'
import { useParams } from 'react-router-dom'
import dateMask from '@/lib/masks/date'
import { castrationUrl } from '@/lib/urlMaker'
import UserCard from '../components/UserCard'
import AnimalCard from '../components/AnimalCard'
import CastrationCard from '../components/CastrationCard'
import ClinicCard from '../components/ClinicCard'

function CastrationShow({ title }) {
  const { id } = useParams()
  usePageTitle({ title: `${title} #${id}` })
  const { data, loading, error } = useAxiosRequest(castrations.show, id)
  return (
    <Styles.Container className="grid-xl">
      <PageHeader title={`Castração #${id}`} icon="fas fa-cut" subtitle={`Código: ${data?.uid}`} />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <a
                className="btn btn-sm btn-link mx-1"
                href={castrationUrl(data)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-print" />
                &nbsp;Imprimir
              </a>
              <div className="my-2">
                Situação: <b>{CASTRATION_STATUS.t(data.status)}</b>
              </div>
              {
                data?.scheduledTo && (
                  <div className="my-2">
                    Agendado para: <b>{dateMask(data?.scheduledTo, { hour: '2-digit', minute: '2-digit' })}</b>
                  </div>
                )
              }
              {
                data?.rejectionReason && (
                  <div className="my-2">
                    Motivo da rejeição: <b>{data?.rejectionReason?.description}</b>
                  </div>
                )
              }
              <div className="divider" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-12 mb-2">
                  <h1 className="h5">Detalhes do solicitante</h1>
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <UserCard
                    title="Tutor"
                    user={data?.tutor}
                    lowIncome={data?.lowIncome}
                    renderFooter={() => (
                      <Card.Footer>
                        {
                          Boolean(data?.tutor?.address?.formattedAddress) && (
                            <>
                              <div className="divider" />
                              {data?.tutor?.address?.formattedAddress}
                            </>
                          )
                        }
                        <div className="divider" />
                        <span className="chip">Castrações ativas: {data?.countCastrationsByTutor}</span>

                      </Card.Footer>
                    )}
                  />
                </Flexbox.Column>
                <Flexbox.Column className="col-2 text-center hide-lg flex-centered">
                  <i className="fas fa-arrow-right fa-2x" />
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <AnimalCard animal={data?.animal} castration={data} />
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-12 mb-2">
                  <h1 className="h5">Detalhes da castração</h1>
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <CastrationCard castration={data} />
                </Flexbox.Column>
                <Flexbox.Column className="col-2 text-center hide-lg flex-centered">
                  <i className="fas fa-arrow-right fa-2x" />
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <ClinicCard preApproved={data?.status === 'preApproved'} clinic={data?.clinic} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default CastrationShow
